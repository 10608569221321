import moment from 'moment';
import { createCrudActions } from '../utils/crudGenerator';
import { axios } from "../connection/ConnectionHandler";
import { keyBy } from "lodash";
import { SET_BUDGET_LIST, SHOW_NOTIFICATION, UPDATE_BUDGET, BUDGET_FORM_TOOGLE_LOADING } from "../constants";
import { API } from "../constants/api";
const formatBudgets = (data) => {
    if (!data) {
        return [];
    }
    const formatItem = (item) => ({
        ...item,
        label: `${item.name}`,
        value: item._id,
        date: item.date ? moment(item.date) : null,
        dueDate: item.dueDate ? moment(item.dueDate) : null
    });

    if (Array.isArray(data)) {
        return data.map(formatItem);
    }
    return formatItem(data);
};

export const {
    fetch: fetchBudgets,
    create: createBudget,
    update: updateBudget,
    delete: deleteBudgets
} = createCrudActions('BUDGET', "Presupuesto", formatBudgets);

export const fetchBudgetById = (id) => async (dispatch) => {
    try {
        const response = await axios.get(`/budget/${id}`);
        const formattedBudget = formatBudgets(response.data);
        const budgetArray = [formattedBudget];
        const budgetObject = keyBy(budgetArray, '_id');

        dispatch({
            type: SET_BUDGET_LIST,
            payload: budgetObject
        });

        return response.status;
    } catch (err) {
        console.error(err);
        dispatch({
            type: SHOW_NOTIFICATION,
            payload: {
                show: true,
                status: "error",
                message: "Error al obtener el presupuesto.",
            },
        });
        return err;
    }
};

export const uploadBudgetSignature = (imageDataURL, id) => async (dispatch) => {
    try {
        const response = await axios.post(`${API.budgets.uploadSignature}${id}`, imageDataURL)
            .then((response) => {
                const budget = formatBudgets(response.data);
                dispatch({ type: UPDATE_BUDGET, payload: budget });
                dispatch({ type: BUDGET_FORM_TOOGLE_LOADING });
                dispatch({
                    type: SHOW_NOTIFICATION,
                    payload: {
                        show: true,
                        status: "info",
                        message: "Firma actualizada.",
                    },
                });
                return response && response.status;
            })
            .catch((err) => {
                dispatch({ type: BUDGET_FORM_TOOGLE_LOADING });
                return err;
            });
        return response;
    } catch (err) {
        console.error(err);
        dispatch({
            type: SHOW_NOTIFICATION,
            payload: {
                show: true,
                status: "error",
                message: "Error al actualizar la firma.",
            },
        });
    }
};

export const createOffer = (id) => async (dispatch) => {
    try {
        const response = await axios.put(`${API.budgets.createOffer}${id}`);
        const budget = formatBudgets(response.data);
        dispatch({ type: UPDATE_BUDGET, payload: budget });
        dispatch({ type: BUDGET_FORM_TOOGLE_LOADING });
        dispatch({
            type: SHOW_NOTIFICATION,
            payload: {
                show: true,
                status: "info",
                message: "Oferta creada.",
            },
        });
        return response.status;
    } catch (err) {
        console.error(err);
        return err;
    }
}

export const acceptOffer = (id, formClient) => async (dispatch) => {
    try {
        const response = await axios.put(`${API.budgets.acceptOffer}${id}`, formClient);
        const budget = formatBudgets(response.data);
        dispatch({ type: UPDATE_BUDGET, payload: budget });
        dispatch({ type: BUDGET_FORM_TOOGLE_LOADING });
        dispatch({
            type: SHOW_NOTIFICATION,
            payload: {
                show: true,
                status: "info",
                message: "Oferta creada.",
            },
        });
        return response.status;
    } catch (err) {
        console.error(err);
        return err;
    }
}

export const sendInvoice = (id) => async (dispatch) => {
    try {
        const response = await axios.post(`${API.budgets.sendInvoice}${id}`);
        return response.status;
    } catch (err) {
        console.error(err);
        return err;
    }
}
